define(['app', 'siteObj', 'viewport','$console'], function(app, siteObj, viewport,$console) {
  const sponsoredProductsHomepage = function () {
    const component = {};

    const _config = {
      selectors: {
        productImage: '[data-track=product-image]',
        productTitle: '[data-track=product-title]',
        productQuickbuy: '[data-component=productQuickbuySimple]',
        productWishlist: '[data-context=addToWishlistButton]',
        productWishlistData: '[data-component=productAddToWishlist]',
        productId: 'data-product-id',
        beacons: {
          viewBeacon: 'data-beacon-view',
          loadBeacon: 'data-beacon-load',
          clickBeacon: 'data-beacon-click',
          wishlistBeacon: 'data-beacon-wishlist',
          basketBeacon: 'data-beacon-basket'
        }
      },
      attributes: {
        customerState: 'data-customer-state',
      },
      classes: {
        productBlockListClass: '.productListProducts_product',
        productBlockClass: '.productListProducts_products',
        sponsoredBlocksClass: '.sponsoredProductsList',
        sponsoredProductsList: '.homepage_sponsoredProducts',
      },
      subscribeChannels: {
        wishlistBeacon: 'criteo/wishlistBeacon'
      }
    };

    const _init = function(element) {
      component.element = element;

      component.addBlocksTracking();
      return component;
    }

    const _addBlocksTracking = function () {
      app.publish('tracking/record', 'SponsoredAds | Homepage', 'enabled');
      const productsDOM = document.querySelector(component.config.classes.productBlockListClass);
      if (!productsDOM) return;

      const sponsoredProductsComponent = document.querySelector(component.config.classes.sponsoredProductsList);
      component.sendBeacon(sponsoredProductsComponent, component.config.selectors.beacons.loadBeacon);
      component.addPlacementTracking(sponsoredProductsComponent);

      const sponsoredProductBlocks = Array.from(document.querySelectorAll(component.config.classes.sponsoredBlocksClass));
      sponsoredProductBlocks.forEach(productBlock => {
        const dataDiv = productBlock.firstElementChild;

        component.sendBeacon(dataDiv, component.config.selectors.beacons.loadBeacon);

        const productImageElement = productBlock.querySelector(component.config.selectors.productImage);
        productImageElement.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));
        productImageElement.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));

        const productTitleElement = productBlock.querySelector(component.config.selectors.productTitle);
        productTitleElement.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));
        productTitleElement.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.clickBeacon));

        const quickbuyButton = productBlock.querySelector(component.config.selectors.productQuickbuy);
        quickbuyButton.addEventListener('click',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.basketBeacon));
        quickbuyButton.addEventListener('auxclick',
          () => component.sendBeacon(dataDiv, component.config.selectors.beacons.basketBeacon));

        app.subscribe(component.config.subscribeChannels.wishlistBeacon, function(sku) {
          if(productBlock.children[0].children[0].getAttribute(component.config.selectors.productId) === sku) {
            component.sendBeacon(dataDiv, component.config.selectors.beacons.wishlistBeacon)
          }
        })

        component.addPlacementTracking(dataDiv);
      });
    };

    const _addPlacementTracking = function (dataElemenet) {
      let viewSent = false;
      if(viewport.isElementFullyVisible(dataElemenet)) {
        component.sendBeacon(dataElemenet, component.config.selectors.beacons.viewBeacon);
        viewSent = true;
      } else {
        document.addEventListener('scroll', function() {
          if (!viewSent && viewport.isElementFullyVisible(dataElemenet)) {
            component.sendBeacon(dataElemenet, component.config.selectors.beacons.viewBeacon);
            viewSent = true;
          }
        });
      }
    }

    const _sendBeacon = function (dataDiv, beaconType) {
      let beacon;
      try {
        beacon = JSON.parse(atob(dataDiv.getAttribute(beaconType)))['url'];
      } catch(error) {
        return;
      }

      let beaconStatus = navigator.sendBeacon(beacon);

      if (beaconStatus && siteObj.debugFlag) {
        $console.log('Beacon sent: ' + beacon + beaconType);
      }
    }

    component.config = _config;
    component.init = _init;
    component.addBlocksTracking = _addBlocksTracking;
    component.addPlacementTracking = _addPlacementTracking;
    component.sendBeacon = _sendBeacon;

    return component;
  }

  return sponsoredProductsHomepage
});
